import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  courseListingStart,
  courseListingSuccess,
  courseListingFailure,
  singleCourseStart,
  singleCourseFailure,
  singleCourseSuccess,
  editCourseStart,
  editCourseFailure,
  editCourseSuccess,
  addCourseStart,
  addCourseFailure,
  addCourseSuccess,
  deleteCourseStart,
  deleteCourseFailure,
  deleteCourseSuccess
} from "../Reducer/courseReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { PaginationParams, ServiceListingResponse, SingleServiceResponse } from "../../interfaces";

export const getCourseListing = createAsyncThunk<ServiceListingResponse, PaginationParams>(
  "course",
  async ({ page, pageSize }, thunkAPI) => {
    try {
      thunkAPI.dispatch(courseListingStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
        params: {
          size: pageSize,
          page,
        },
      };

      const response = await axios.get<ServiceListingResponse>(
        `${process.env.REACT_APP_API_URl}CourseRoutes/CoursesListing`,
        config
      );

      thunkAPI.dispatch(courseListingSuccess(response));

      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || "Failed to fetch course details";

      thunkAPI.dispatch(courseListingFailure(errorMessage));
      throw error;
    }
  }
);


export const getSingleCourse = createAsyncThunk<SingleServiceResponse, { id: string }, { rejectValue: string }>(
  "course/getSingleCourse",
  async ({ id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(singleCourseStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get<SingleServiceResponse>(
        `${process.env.REACT_APP_API_URl}CourseRoutes/SingleCourse/${id}`,
        config
      );

      thunkAPI.dispatch(singleCourseSuccess(response.data));
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || "Failed to fetch course details";
      thunkAPI.dispatch(singleCourseFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const addCourse = createAsyncThunk(
  "course/addCourse",
  async (formData: FormData, thunkAPI) => {
    try {
      thunkAPI.dispatch(addCourseStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URl}CourseRoutes/CreateCourse`,
        formData,
        config
      );

      thunkAPI.dispatch(addCourseSuccess(response));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to add course!";
      thunkAPI.dispatch(addCourseFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const editCourse = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
  "course/editCourse",
  async ({id, formData}, thunkAPI) => {
    try {
      thunkAPI.dispatch(editCourseStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URl}CourseRoutes/EditCourse/${id}`,
        formData,
        config
      );

      thunkAPI.dispatch(editCourseSuccess(response));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to edit course!";
      thunkAPI.dispatch(editCourseFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const deleteCourse = createAsyncThunk(
  "service/deleteCourse",
  async (id: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(deleteCourseStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_API_URl}CourseRoutes/SoftDeleteCourse/${id}`, config
      );

      thunkAPI.dispatch(deleteCourseSuccess(response.data));
      return response.data;
    } catch (error: any) {
      if (error) {
        const errorMessage = error?.response?.data?.message;
        thunkAPI.dispatch(deleteCourseFailure(errorMessage));
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  }
);