import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, message, Select, Upload, Spin, InputNumber } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { editBook, getSingleBook } from '../../Redux/Action/books';
import { AppDispatch, RootState } from '../../Store';
import { LoadingOutlined } from '@ant-design/icons';
import { getInstructorListing } from '../../Redux/Action/Instructor';
import { clearState } from '../../Redux/Reducer/booksReducer';
import Loading from '../../Lottie/Loading';

const BookDetailPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { data: instructorData } = useSelector((state: RootState) => state.instructor);
    const { data: bookData, loading, editLoading, editSuccess, editError } = useSelector((state: RootState) => state.book);
    const { id } = useParams();
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState<UploadFile[]>([]);

    useEffect(() => {
        dispatch(getInstructorListing({ page: 1, pageSize: 100 }));
        if (id) dispatch(getSingleBook({ id }));
    }, [dispatch, id]);

    useEffect(() => {
        if (bookData) {
            form.setFieldsValue({
                AuthorName: bookData.InstructorId || '',
                Name: bookData.Name || '',
                Price: bookData.Price || '',
                Duration: bookData.Duration || '',
                PdfLink: bookData.PdfLink || '',
                AudioLink: bookData.AudioLink || '',
                Description: bookData.Description || '',
                BgImage: bookData.BgImage || ''
            });

            // setFileList([
            //     {
            //         uid: '-1',
            //         name: 'BgImage',
            //         status: 'done',
            //         url: bookData.BgImage,
            //     },
            // ]);
        }
    }, [bookData, form]);

    useEffect(() => {
        if (editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if (editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            navigate('/books');
        }
    }, [editSuccess, editError]);

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Name', values.Name);
        formData.append('Description', values.Description);
        formData.append('Price', values.Price);
        formData.append('Duration', values.Duration);
        formData.append('AudioLink', values.AudioLink);
        formData.append('PdfLink', values.PdfLink);
        formData.append('Instructor', values.AuthorName);
        formData.append('BgImage', values.BgImage);

        // if (fileList[0] && fileList[0].originFileObj) {
        //     formData.append('BgImage', fileList[0].originFileObj);
        // }

        if (id) {
            dispatch(editBook({ id, formData }));
        }
    };

    // const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    //     setFileList(newFileList);
    // };

    // const onPreview = async (file: UploadFile) => {
    //     let src = file.url as string;
    //     if (!src) {
    //         src = await new Promise((resolve) => {
    //             const reader = new FileReader();
    //             reader.readAsDataURL(file.originFileObj as File);
    //             reader.onload = () => resolve(reader.result as string);
    //         });
    //     }
    //     const image = new Image();
    //     image.src = src;
    //     const imgWindow = window.open(src);
    //     imgWindow?.document.write(image.outerHTML);
    // };

    return (
        <div className="container-fluid servicess">
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Book Details</h2>
                </div>
                {loading ? (
                    <div><Loading /></div>
                ) : (
                    <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                        <Form layout="vertical" form={form} initialValues={bookData} onFinish={onFinish}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form.Item label="Book Name" name="Name">
                                        <Input placeholder="Name" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Author Name"
                                        name="AuthorName"
                                        rules={[{ required: true, message: 'Please select the Instructor!' }]}
                                    >
                                        <Select placeholder="Instructor">
                                            {instructorData?.items?.map((instructor: any, index: number) => (
                                                <Select.Option value={instructor._id} key={index}>
                                                    {instructor.Name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Book Price"
                                        name="Price"
                                        rules={[
                                            { required: true, message: 'Please input the Price!' }
                                        ]}
                                    >
                                        <InputNumber placeholder="Price" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Duration"
                                        name="Duration"
                                        rules={[{ required: true, message: 'Please input the Duration in mins or hours!' }]}
                                    >
                                        <Input placeholder="Duration" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Audio Link"
                                        name="AudioLink"
                                        rules={[
                                            { required: true, message: 'Please input the Audio Link!' },
                                            { type: 'url', message: 'Please enter a valid URL!' },
                                        ]}
                                    >
                                        <Input placeholder="Audio Link" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Pdf Link"
                                        name="PdfLink"
                                        rules={[
                                            { required: true, message: 'Please input the Pdf Link!' },
                                            { type: 'url', message: 'Please enter a valid URL!' },
                                        ]}
                                    >
                                        <Input placeholder="PdfLink" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-6">
                                    <Form.Item
                                        label="Bg Image URL"
                                        name="BgImage"
                                        rules={[
                                            { required: true, message: 'Please input the Bg Image Link!' },
                                            { type: 'url', message: 'Please enter a valid URL!' },
                                        ]}
                                    >
                                        <Input placeholder="Bg Image URL" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12">
                                    <Form.Item
                                        label="Description"
                                        name="Description"
                                        rules={[{ required: true, message: 'Please input the description!' }]}
                                    >
                                        <Input.TextArea rows={5} placeholder="Description" />
                                    </Form.Item>
                                </div>
                                <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                    <Button type="primary" htmlType="submit" disabled={editLoading}>
                                        {editLoading ? <Spin indicator={<LoadingOutlined spin />} /> : 'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BookDetailPage;

                                // <div className="col-sm-12">
                                //     <Form.Item label="Book Image">
                                //         <Upload
                                //             listType="picture-card"
                                //             fileList={fileList}
                                //             onChange={onChange}
                                //             onPreview={onPreview}
                                //             maxCount={1}
                                //         >
                                //             {fileList.length < 1 && '+ Upload'}
                                //         </Upload>
                                //     </Form.Item>
                                // </div>