import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { AppDispatch, RootState } from '../../Store';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, getUserListing } from '../../Redux/Action/user';
import { clearState } from '../../Redux/Reducer/userReducer';
import Pagination from '../Pagination/Pagination';
import Loading from '../../Lottie/Loading';
import DeleteModal from '../DeleteModal/DeleteModal';

const Page = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data, deleteError, deleteSuccess, loading, deleteLoading } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    const handlePageChange = (page: number, pageSize?: number) => {
        setPage(page);
        setPageSize(pageSize || 10);
    };

    const handleDelete = async () => {
        if (id) {
            console.log(id)
            await dispatch(deleteUser(id));
            setShow(false);
        }
        else {
            message.error("User does not exist!")
        }
    };

    const handleShow = (id: string) => {
        setShow(true);
        setId(id);
    }
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        dispatch(getUserListing({ page, pageSize }));
    }, [page, pageSize])

    useEffect(() => {
        if (deleteError) {
            message.error(deleteError);
            dispatch(clearState());
        }
        if (deleteSuccess) {
            message.success(deleteSuccess);
            dispatch(clearState());
            dispatch(getUserListing({ page, pageSize }));
        }
    }, [dispatch, deleteError, deleteSuccess])

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Users</h2>
                </div>
            </div>
            {
                loading ?
                    <div><Loading /></div>
                    :
                    <div className="row">

                        <div className="col-sm-12 ttable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Name</th>
                                        <th>Email Address</th>
                                        <th>Gender</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.items?.length > 0 ?
                                        
                                        data?.items?.map((user: any, index: number) => (
                                            <tr key={user._id}>
                                                <td onClick={() => navigate(`/users/${user._id}`)}>{index + 1}</td>
                                                <td onClick={() => navigate(`/users/${user._id}`)}><b>{user?.Name}</b></td>
                                                <td onClick={() => navigate(`/users/${user._id}`)}>{user?.Email}</td>
                                                <td onClick={() => navigate(`/users/${user._id}`)}>{user?.Gender ? user?.Gender : 'Not Specified'}</td>
                                                <td className='deleete'>
                                                    <div className='actionsss'>
                                                        <DeleteOutlined className='del' onClick={() => handleShow(user._id)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        : 

                                        <tr className='noDataa'>
                                            <td colSpan={5}>No Data</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="col-sm-12 paginatitton">
                        <Pagination
    TotalData={data?.totalCount || 0}
    handlePageChange={handlePageChange}
    currentPage={page}
    pageSize={pageSize} 
/>
                        </div>
                    </div>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <DeleteModal handleClose={handleClose} handleDelete={handleDelete} loading={deleteLoading} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Page;