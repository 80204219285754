import React from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import EditBlogsPage from '../Components/Blogs/EditBlogsPage'

const EditBlogs = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-sm-3 sidebar">
                    <Sidebar />
                </div>
                <div className="col-sm-9 noSide">
                    <EditBlogsPage />
                </div>
            </div>
        </div>
    )
}

export default EditBlogs
