import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type QuillEditorProps = {
    onChange: (value: string) => void;
    placeholder?: string;
    data?: string;
};

const QuillEditor: React.FC<QuillEditorProps> = ({ onChange, placeholder, data }) => {
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
    ];

    return (
        <div   style={{
            marginBottom:"20px"
        }}>
            <ReactQuill
                onChange={onChange}
                value={data}
                modules={modules}
                formats={formats}
                placeholder={placeholder}
                defaultValue={data}
                style={{
                    height:"200px"
                }}
            />
        </div>
    );
};

export default QuillEditor;
