import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    bookListingStart,
    bookListingSuccess,
    bookListingFailure,
    deleteBookFailure,
    deleteBookStart,
    deleteBookSuccess,
    getSingleBookStart,
    getSingleBookSuccess,
    getSingleBookFailure,
    editBookStart,
    editBookFailure,
    editBookSuccess,
    addBookFailure,
    addBookStart,
    addBookSuccess,
} from "../Reducer/booksReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { bookResponse, getSingleBookResponse, PaginationParams, SingleServiceResponse } from "../../interfaces";

export const getBookListing = createAsyncThunk<bookResponse, PaginationParams>(
    "book",
    async ({ page, pageSize }, thunkAPI) => {
        try {
            thunkAPI.dispatch(bookListingStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<bookResponse>(
                `${process.env.REACT_APP_API_URl}BookRoutes/BookListing`,
                config
            );

            thunkAPI.dispatch(bookListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch books";

            thunkAPI.dispatch(bookListingFailure(errorMessage));
            throw error;
        }
    }
);

export const deleteBook = createAsyncThunk(
    "book/deleteBook",
    async (id: string, thunkAPI) => {
        try {
            thunkAPI.dispatch(deleteBookStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.delete(
                `${process.env.REACT_APP_API_URl}BookRoutes/SoftDeleteBook/${id}`, config
            );

            thunkAPI.dispatch(deleteBookSuccess(response.data));
            return response.data;
        } catch (error: any) {
            if (error) {
                const errorMessage = error?.response?.data?.message;
                thunkAPI.dispatch(deleteBookFailure(errorMessage));
                return thunkAPI.rejectWithValue(errorMessage);
            }
        }
    }
);

export const getSingleBook = createAsyncThunk<getSingleBookResponse, { id: string }, { rejectValue: string }>(
    "book/getSingleBook",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getSingleBookStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<getSingleBookResponse>(
                `${process.env.REACT_APP_API_URl}BookRoutes/GetSingleBook/${id}`,
                config
            );

            thunkAPI.dispatch(getSingleBookSuccess(response?.data?.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch book details";
            thunkAPI.dispatch(getSingleBookFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const addBook = createAsyncThunk(
    "book/addBook",
    async (formData: FormData, thunkAPI) => {
        try {
            thunkAPI.dispatch(addBookStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URl}BookRoutes/CreateBook`,
                formData,
                config
            );

            thunkAPI.dispatch(addBookSuccess(response));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to add book!";
            thunkAPI.dispatch(addBookFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const editBook = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
    "book/editBook",
    async ({ id, formData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(editBookStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.put(
                `${process.env.REACT_APP_API_URl}BookRoutes/EditBook/${id}`,
                formData,
                config
            );

            thunkAPI.dispatch(editBookSuccess(response));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to edit book!";
            thunkAPI.dispatch(editBookFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);