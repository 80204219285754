import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    blogsListingStart,
    blogsListingSuccess,
    blogsListingFailure,
    deleteblogsFailure,
    deleteblogsStart,
    deleteblogsSuccess,
    getSingleblogsStart,
    getSingleblogsSuccess,
    getSingleblogsFailure,
    editblogsStart,
    editblogsFailure,
    editblogsSuccess,
    addblogsFailure,
    addblogsStart,
    addblogsSuccess,
} from "../Reducer/blogsReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { bookResponse, getSingleBookResponse, PaginationParams, SingleblogsResponse } from "../../interfaces";

export const getblogsListing = createAsyncThunk<bookResponse, PaginationParams>(
    "blogs",
    async ({ page, pageSize }, thunkAPI) => {
        try {
            thunkAPI.dispatch(blogsListingStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<bookResponse>(
                `${process.env.REACT_APP_API_URl}BlogsRoutes/BlogListing`,
                config
            );

            thunkAPI.dispatch(blogsListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch blogs";

            thunkAPI.dispatch(blogsListingFailure(errorMessage));
            throw error;
        }
    }
);

export const deleteblogs = createAsyncThunk(
    "blogs/deleteblogs",
    async (id: string, thunkAPI) => {
        try {
            thunkAPI.dispatch(deleteblogsStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.delete(
                `${process.env.REACT_APP_API_URl}BlogsRoutes/SoftDeleteBlog/${id}`, config
            );

            thunkAPI.dispatch(deleteblogsSuccess(response.data));
            return response.data;
        } catch (error: any) {
            if (error) {
                const errorMessage = error?.response?.data?.message;
                thunkAPI.dispatch(deleteblogsFailure(errorMessage));
                return thunkAPI.rejectWithValue(errorMessage);
            }
        }
    }
);

export const getSingleblogs = createAsyncThunk<getSingleBookResponse, { id: string }, { rejectValue: string }>(
    "blogs/getSingleblogs",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(getSingleblogsStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<getSingleBookResponse>(
                `${process.env.REACT_APP_API_URl}BlogsRoutes/GetSingleBlog/${id}`,
                config
            );

            thunkAPI.dispatch(getSingleblogsSuccess(response?.data?.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch blogs details";
            thunkAPI.dispatch(getSingleblogsFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const addblogs = createAsyncThunk(
    "blogs/addblogs",
    async (formData: FormData, thunkAPI) => {
        try {
            thunkAPI.dispatch(addblogsStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.post(
                `${process.env.REACT_APP_API_URl}BlogsRoutes/CreateBlog`,
                formData,
                config
            );

            thunkAPI.dispatch(addblogsSuccess(response));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to add blogs!";
            thunkAPI.dispatch(addblogsFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const editblogs = createAsyncThunk<SingleblogsResponse, { id: string, formData: FormData }, { rejectValue: string }>(
    "blogs/editSerive",
    async ({ id, formData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(editblogsStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.put(
                `${process.env.REACT_APP_API_URl}BlogsRoutes/EditBlog/${id}`,
                formData,
                config
            );

            thunkAPI.dispatch(editblogsSuccess(response));
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Failed to edit blogs!";
            thunkAPI.dispatch(editblogsFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);