import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Image } from 'antd';
import { AppDispatch, RootState } from '../../Store';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Loading from '../../Lottie/Loading';
import sample from '../../Assets/avatar.jpg'
import Users from '../../Data/Users.json'; 
import { getSingleInstructor } from '../../Redux/Action/Instructor';

const InstructorDetail = () => {
    const { id } = useParams(); 
    // const uId = parseInt('1');
    // const user = Users.find(s => s._id === uId); 
    const dispatch = useDispatch<AppDispatch>();
    const { data, loading } = useSelector((state: RootState) => state.instructor);
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            dispatch(getSingleInstructor({ id }));
        }
    }, [dispatch, id]);


    const onFinish = (values: any) => {
        console.log('Updated service details:', values);
    };

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                Email: data?.Email,
                Name: data?.Name,
                CoursesCount: data?.CoursesCount,
                InstructorReviewsCount: data?.InstructorReviewsCount,
                // DOB: data?.DOB ? moment(data?.DOB).format("MMM DD, YYYY") : ''
            })
        }
    }, [data])


    return (
        <div className='container-fluid servicess userdetailll'>
            {
                // loading ?
                //     <div><Loading /></div>
                //     :
                    <div className="row">
                        <div className="col-sm-12 addnew">
                            <h2>Instructor Detail</h2>
                        </div>

                        {data?.Profile ?
                            <div className="col-sm-12" style={{ padding: '30px 15px 0' }}>
                                <Image src={data?.Profile} style={{ width: '300px', height: '300px', borderRadius: '50%' }} />
                            </div>
                            :
                            <div className="col-sm-12" style={{ padding: '30px 15px 0' }}>
                                <img src={sample} style={{ width: '300px', height: '300px', borderRadius: '50%' }} alt='profile'/>
                            </div>
                        }


                        <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                            <Form layout='vertical' onFinish={onFinish} form={form} initialValues={data}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Name"
                                            name="Name"
                                        >
                                            <Input readOnly placeholder='Name' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Email"
                                            name="Email"
                                        >
                                            <Input readOnly placeholder='Email' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="Number Of Courses"
                                            name="CoursesCount"
                                        >
                                            <Input readOnly placeholder='Courses Count' />
                                        </Form.Item>
                                    </div>

                                    <div className="col-sm-6">
                                        <Form.Item
                                            label="InstructorReviewsCount"
                                            name="InstructorReviewsCount"
                                        >
                                            <Input readOnly placeholder='InstructorReviewsCount' />
                                        </Form.Item>
                                    </div>

                                    {/* <div className="col-sm-6">
                                        <Form.Item
                                            label="DOB"
                                            name="DOB"
                                        >
                                            <Input readOnly placeholder='DOB' />
                                        </Form.Item>
                                    </div> */}



                                    {/* <div className="col-sm-12" style={{textAlign: 'right', marginTop: '20px'}} >
                                    <Button type="primary" htmlType="submit">Submit</Button>
                                </div> */}
                                </div>
                            </Form>
                        </div>
                    </div>
            }
        </div>
    );
};

export default InstructorDetail;