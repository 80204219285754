import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input, InputNumber, message, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { editWorkshop, getSingleWorkshop } from '../../Redux/Action/workshop';
import Loading from '../../Lottie/Loading';
import { getInstructorListing } from '../../Redux/Action/Instructor';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { clearState } from '../../Redux/Reducer/workshopReducer';

const DetailPage = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const dispatch = useDispatch<AppDispatch>();
    const { data } = useSelector((state: RootState) => state.instructor);
    const { workshopData, loading, editError, editSuccess } = useSelector((state: RootState) => state.workshop);
    const [form] = Form.useForm();

    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch])

    useEffect(() => {
        if (id) {
            dispatch(getSingleWorkshop({ id }));
        }
    }, [dispatch, id]);

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Name', values.Name);
        formData.append('Description', values.Description);
        formData.append('Price', values.Price);
        formData.append('Duration', values.Duration);
        formData.append('Instructor', values.InstructorName);
        formData.append('File', values.File);

        if(id) {
            dispatch(editWorkshop({id, formData}));
        }
    };  

    useEffect(() => {
        if (workshopData) {
            form.setFieldsValue({
                Name: workshopData?.Name,
                InstructorName: workshopData?.InstructorId,
                Duration: workshopData?.Duration,
                Description: workshopData?.Description,
                File: workshopData?.File,
                Price: workshopData?.Price
            });
        }
    }, [workshopData]);

    useEffect(() => {
        if(editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if(editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            navigate('/workshops');
        }
    }, [editSuccess, editError]);

    if (!workshopData) {
        return <div>Workshop not found</div>;
    }

    return (
        <div className='container-fluid servicess'>
            {loading ? (
                <div>
                    <Loading />
                </div>
            ) : (
                <div className="row">
                    <div className="col-sm-12 addnew" style={{ marginBottom: '20px' }}>
                        <h2>Workshop Details</h2>
                    </div>

                    <div className="col-sm-12">
                        <Form
                            initialValues={workshopData}
                            layout='vertical'
                            form={form}
                            onFinish={onFinish}
                        >
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form.Item
                                        name="Name"
                                        label="Name"
                                        rules={[{ required: true, message: 'Please input workshop name!' }]}
                                    >
                                        <Input placeholder='Name' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        name="InstructorName"
                                        label="Instructor Name"
                                        rules={[{ required: true, message: 'Please select the Instructor!' }]}
                                    >
                                        <Select
                                            placeholder="Instructor"
                                        >
                                            {data?.items?.map((instructor: any, index: number) => (
                                                <Select.Option value={instructor?._id} key={index}>
                                                    {instructor?.Name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        name="Duration"
                                        label="Duration"
                                        rules={[{ required: true, message: 'Please input the Duration in mins or hours!' }]}
                                    >
                                        <Input placeholder='Duration' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        name="Price"
                                        label="Price"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input the Price!'
                                            }
                                        ]}
                                    >
                                        <InputNumber min={1} placeholder='Price' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        name="File"
                                        label="Image Url"
                                        rules={[
                                            { 
                                                required: true, 
                                                message: 'Please input the Image Url!' 
                                            },
                                            {
                                                type: 'url',
                                                message: 'Please enter a valid URL!'
                                            }
                                        ]}
                                    >
                                        <Input placeholder='Image Url' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12">
                                    <Form.Item
                                        name="Description"
                                        label="Description"
                                        rules={[{ required: true, message: 'Please input the description!' }]}
                                    >
                                        <Input.TextArea rows={5} placeholder='Description' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                    <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                        {loading ?
                                            <>
                                                <Spin indicator={<LoadingOutlined spin />} />
                                            </>
                                            :
                                            <></>
                                        }
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailPage;