import React from 'react';
import CodeResett from '../Components/Login/CodeResett';

const ResetCode = () => {
  return (
    <div className='container-fluid'>
      <div className="row">
        <div className="col-sm-12">
          <CodeResett />
        </div>
      </div>
    </div>
  );
};

export default ResetCode;
