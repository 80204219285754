import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    helpcenterListingStart,
    helpcenterListingSuccess,
    helpcenterListingFailure,
    singlehelpcenterStart,
    singlehelpcenterFailure,
    singlehelpcenterSuccess,
    deletehelpcenterStart,
    deletehelpcenterFailure,
    deletehelpcenterSuccess,
} from "../Reducer/helpcenterReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getSingleUserResponse, PaginationParams, userListing } from "../../interfaces";

export const gethelpcenterListing = createAsyncThunk<userListing, PaginationParams>(
    "helpcenter",
    async ({ page, pageSize }, thunkAPI) => {
        try {
            thunkAPI.dispatch(helpcenterListingStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<userListing>(
                `${process.env.REACT_APP_API_URl}HelpCenterRoutes/HelpCenterListing`,
                config
            );

            thunkAPI.dispatch(helpcenterListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch helpcenter";

            thunkAPI.dispatch(helpcenterListingFailure(errorMessage));
            throw error;
        }
    }
);


export const getSinglehelpcenter = createAsyncThunk<getSingleUserResponse, { id: string }, { rejectValue: string }>(
    "helpcenter/getSinglehelpcenter",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(singlehelpcenterStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<getSingleUserResponse>(
                `${process.env.REACT_APP_API_URl}helpcenterRoutes/InstructoProfile/${id}`,
                config
            );

            thunkAPI.dispatch(singlehelpcenterSuccess(response.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch helpcenter details";
            thunkAPI.dispatch(singlehelpcenterFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const deletehelpcenter = createAsyncThunk(
    "helpcenter/deletehelpcenter",
    async (id: string, thunkAPI) => {
        try {
            thunkAPI.dispatch(deletehelpcenterStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.delete(
                `${process.env.REACT_APP_API_URl}HelpCenterRoutes/DeleteHelpCenter/${id}`, config
            );

            thunkAPI.dispatch(deletehelpcenterSuccess(response.data));
            return response.data;
        } catch (error: any) {
            if (error) {
                const errorMessage = error?.response?.data?.message;
                thunkAPI.dispatch(deletehelpcenterFailure(errorMessage));
                return thunkAPI.rejectWithValue(errorMessage);
            }
        }
    }
);
