import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { deleteWorkshop, getWorkshopsListing } from '../../Redux/Action/workshop';
import { clearState } from '../../Redux/Reducer/workshopReducer';
import Pagination from '../Pagination/Pagination';
import Loading from '../../Lottie/Loading';
import DeleteModal from '../DeleteModal/DeleteModal';

const WorkshopListings = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { workshopData, deleteLoading, deleteError, deleteSuccess, loading } = useSelector((state: RootState) => state.workshop);
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    const handleDelete = async () => {
        if (id) {
            console.log(id)
            await dispatch(deleteWorkshop(id));
            setShow(false);
        }
        else {
            message.error("Workshop does not exist!")
        }
    };

    const handleShow = (id: string) => {
        setId(id);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setId('');
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        setPage(page);
        setPageSize(pageSize || 10);
    };

    useEffect(() => {
        dispatch(getWorkshopsListing({ page, pageSize }));
    }, [page, pageSize])

    useEffect(() => {
        if (deleteError) {
            message.error(deleteError);
            dispatch(clearState());
        }
        if (deleteSuccess) {
            message.success(deleteSuccess);
            dispatch(clearState());
            dispatch(getWorkshopsListing({ page, pageSize }));
        }
    }, [dispatch, deleteError, deleteSuccess])

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Workshops</h2>
                    <Button onClick={() => navigate('/workshops/add')}>Add New</Button>
                </div>
            </div>
            {
                loading
                    ?
                    <div>
                        <Loading />
                    </div>
                    :

                    <div className="row">
                        <div className="col-sm-12 ttable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Workshop Name</th>
                                        {/* <th>Email Address</th> */}
                                        <th>Instructor</th>
                                        <th>Duration</th>
                                        <th>Price</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        workshopData?.items?.length > 0 ? workshopData?.items?.map((workshop: any, index: number) => (
                                            <tr key={workshop._id} >
                                                <td onClick={() => navigate(`/workshops/${workshop._id}`)}>{index + 1}</td>
                                                {/* <td onClick={() => navigate(`/workshops/${workshop._id}`)}><b>{workshop?.UserRequestQuotes?.Email}</b></td> */}
                                                <td onClick={() => navigate(`/workshops/${workshop._id}`)}><b>{workshop?.Name}</b></td>
                                                <td onClick={() => navigate(`/workshops/${workshop._id}`)}><b>{workshop?.InstructorName}</b></td>
                                                <td onClick={() => navigate(`/workshops/${workshop._id}`)}>{workshop?.Duration}</td>
                                                <td onClick={() => navigate(`/workshops/${workshop._id}`)}>{workshop?.Price}</td>
                                                


                                                <td className='deleete'>
                                                    <div className='actionsss' onClick={(e) => e.stopPropagation()}>
                                                        <DeleteOutlined className='del' onClick={() => handleShow(workshop._id)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                            : <tr className='noDataa'>
                                                <td colSpan={6}>No Data</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="col-sm-12 paginatitton">
                            <Pagination
                                TotalData={workshopData?.totalCount || 0}
                                handlePageChange={handlePageChange}
                                currentPage={page}
                            />
                        </div>
                    </div>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <DeleteModal handleClose={handleClose} handleDelete={handleDelete} loading={deleteLoading} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default WorkshopListings;
