import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { Button, Form, Input, message } from 'antd';
import { clearState } from '../../Redux/Reducer/courseSectionReducer';
import { editcourseSection } from '../../Redux/Action/courseSection';

interface EditCourseSectionModalProps {
    id?: any;
    handleEditSectionModalClose: () => void;
    getDataAgain: () => void;
    namesSection: string;
}

const EditCourseSection: React.FC<EditCourseSectionModalProps> = ({id, handleEditSectionModalClose, getDataAgain, namesSection}) => {
    const dispatch = useDispatch<AppDispatch>();
    const { editSuccess, editError } = useSelector((state: RootState) => state.courseSection);
    useEffect(() => {
        if (editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if (editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            handleEditSectionModalClose();
            getDataAgain();
        }
    }, [editSuccess, editError]);

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('Name', values.Name);
        dispatch(editcourseSection({id, formData}))
    };
    const [form] = Form.useForm();


    useEffect(() => {
        if (namesSection) {
            form.setFieldsValue({
                Name: namesSection
            });
        }
    }, [namesSection]);
    return (
        <div className="container-fluid servicess">
            <Form onFinish={onFinish} layout="vertical" initialValues={{ Name: namesSection }}>
                <div className="row">
                    <div className="col-sm-12">
                        <Form.Item
                            label="Name"
                            name="Name"
                            rules={[{ required: true, message: 'Please input the Name!' }]}
                        >
                            <Input placeholder="Name" />
                        </Form.Item>
                    </div>

                    <div className="col-sm-12" style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default EditCourseSection