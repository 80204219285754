import React, { useEffect } from 'react';
import img1 from '../../Assets/logo.png';
import { Form, FormProps, Button, Input, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ResetPassword } from '../../Redux/Action/authAdmin';
import { clearState } from '../../Redux/Reducer/authAdminReducer';
import type { RootState, AppDispatch } from '../../Store';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const NewPasswordForm: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>(); 
    const { error, success, loading } = useSelector((state: RootState) => state.authAdmin);
    const navigate = useNavigate();
    const { Token } = useParams<{ Token: string }>();
    type FieldType = {
        firstPassword: string;
        confirmPassword: string;
    };

    const validatePassword = (_: any, value: string) => {
        if (value && value.length < 7) {
          return Promise.reject("Password must be at least 7 characters long.");
        }
        return Promise.resolve(); 
    };

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        const formData = new FormData();
        formData.append('firstPassword', values.firstPassword);
        formData.append('confirmPassword', values.confirmPassword);
        if(Token)
        {
            await dispatch(ResetPassword({Token, formData}));
        }
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (error) {
            message.error(error);
            dispatch(clearState());
        }
        if (success) {
            message.success(success);
            navigate('/');
            dispatch(clearState());
        }
    }, [error, success, dispatch, navigate]);

    return (
        <div>
            <div className="container-fluid loginComp">
                <div className="row">
                    <div className="col-sm-6">
                        <img src={img1} alt="Logo" />
                        <Form
                            name="login"
                            initialValues={{ remember: false }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item<FieldType>
                                label="First Password"
                                name="firstPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your First Password!',
                                    },
                                    { 
                                        validator: validatePassword 
                                    }
                                ]}
                            >
                                <Input.Password className="inpputt" placeholder="First Password" />
                            </Form.Item>

                            <Form.Item<FieldType>
                                label="Confirm Password"
                                name="confirmPassword"
                                rules={[
                                    { 
                                        required: true, 
                                        message: 'Please input your Confirm Password!' 
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                          if (!value || getFieldValue("firstPassword") === value) {
                                            return Promise.resolve();
                                          }
                                          return Promise.reject("The two passwords do not match");
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                {loading ?
                            <>
                                <Spin indicator={<LoadingOutlined spin />} />
                            </>
                            :
                            <></>
                        }
                                    Reset Password
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewPasswordForm;