import { createSlice } from "@reduxjs/toolkit";

interface courseSectionState {
    loading: boolean;
    editLoading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    editError: string | null;
    editSuccess: string | null;
    postError: string | null;
    postSuccess: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    data: any;
}

const initialState: courseSectionState = {
    loading: false,
    deleteLoading: false,
    editLoading: false,
    error: null,
    success: null,
    editError: null,
    editSuccess: null,
    postError: null,
    postSuccess: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const courseSectionSlice = createSlice({
    name: "courseSection",
    initialState,
    reducers: {
        courseSectionListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        courseSectionListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        courseSectionListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singlecourseSectionStart(state) {
            state.loading = true;
            state.error = null;
        },
        singlecourseSectionSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.success = action.payload.data.message;
        },
        singlecourseSectionFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        editcourseSectionStart(state) {
            state.editLoading = true;
            state.editError = null;
        },
        editcourseSectionSuccess(state, action) {
            state.editLoading = false;
            state.data = action.payload.data;
            state.editSuccess = action.payload.data.message;
            
        },
        editcourseSectionFailure(state, action) {
            state.editLoading = false;
            state.editError = action.payload;
        },
        addcourseSectionStart(state) {
            state.loading = true;
            state.postError = null;
        },
        addcourseSectionSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.postSuccess = action.payload.data.message;
        },
        addcourseSectionFailure(state, action) {
            state.loading = false;
            state.postError = action.payload;
        },
        deletecourseSectionStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deletecourseSectionSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deletecourseSectionFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.editSuccess = null;
            state.editError = null;
            state.postError = null;
            state.postSuccess = null;
            state.deleteError = null;
            state.deleteSuccess = null;
        },
    },
});

export const {
    courseSectionListingStart,
    courseSectionListingSuccess,
    courseSectionListingFailure,
    singlecourseSectionStart,
    singlecourseSectionFailure,
    singlecourseSectionSuccess,
    editcourseSectionStart,
    editcourseSectionFailure,
    editcourseSectionSuccess,
    addcourseSectionStart,
    addcourseSectionFailure,
    addcourseSectionSuccess,
    deletecourseSectionStart,
    deletecourseSectionFailure,
    deletecourseSectionSuccess,
    clearState,
} = courseSectionSlice.actions;

export default courseSectionSlice.reducer;