import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

interface blogsState {
    loading: boolean;
    deleteLoading: boolean;
    editLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    editError: string | null;
    editSuccess: string | null;
    postError: string | null;
    postSuccess: string | null;
    data: any;
}

const initialState: blogsState = {
    loading: false,
    editLoading: false,
    editError: null,
    deleteLoading: false,
    error: null,
    success: null,
    postSuccess: null,
    postError: null,
    editSuccess: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const blogsSlice = createSlice({
    name: "blogs",
    initialState,
    reducers: {
        blogsListingStart(state) {
            state.loading = true;
            state.error = null;
        },
        blogsListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        blogsListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteblogsStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteblogsSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteblogsFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        getSingleblogsStart(state) {
            state.loading = true;
        },
        getSingleblogsSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        getSingleblogsFailure(state, action) {
            state.loading = false;
            message.error(action.payload)
        },
        editblogsStart(state) {
            state.editLoading = true;
            state.editError = null;
        },
        editblogsSuccess(state, action) {
            state.editLoading = false;
            state.data = action.payload.data;
            state.editSuccess = action.payload.data.message;
        },
        editblogsFailure(state, action) {
            state.editLoading = false;
            state.editError = action.payload;
        },
        addblogsStart(state) {
            state.loading = true;
            state.postError = null;
        },
        addblogsSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.postSuccess = action.payload.data.message;
        },
        addblogsFailure(state, action) {
            state.loading = false;
            state.postError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.deleteLoading = false;
            state.editLoading = false;
            state.editError = null;
            state.editSuccess = null;
            state.postError = null;
            state.postSuccess = null;
        },
    },
});

export const {
    blogsListingStart,
    blogsListingSuccess,
    blogsListingFailure,
    deleteblogsFailure,
    deleteblogsStart,
    deleteblogsSuccess,
    getSingleblogsStart,
    getSingleblogsSuccess,
    getSingleblogsFailure,
    editblogsStart,
    editblogsFailure,
    editblogsSuccess,
    addblogsFailure,
    addblogsStart,
    addblogsSuccess,
    clearState,
} = blogsSlice.actions;

export default blogsSlice.reducer;