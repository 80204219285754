import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile } from "antd/es/upload/interface";
import QuillEditor from "./QuillEditor";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Store";
import { clearState } from "../../Redux/Reducer/blogsReducer";
import { useNavigate } from "react-router-dom";
import { addblogs } from "../../Redux/Action/blogs";

const { Dragger } = Upload;

const AddBlogPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [quillData, setQuillData] = useState("");
  const [fileProfile, setFileProfile] = useState<RcFile | null>(null);

  // Store both the file and its preview
  const [uploadedFileProfile, setUploadedFileProfile] = useState<{ 
    preview: string;
    file: RcFile | null;
  } | null>(null);

  const { postError, postSuccess, loading } = useSelector((state: RootState) => state.blogs);

  const handleQuillChange = (html: string) => {
    setQuillData(html);
  };

  const onFinish = (values: any) => {
   
    const formData = new FormData();
    formData.append("Title", values.Title);
    formData.append("Description", values.Description);
    formData.append("BgImage", values.BgImage); // Correctly append the file
  
    dispatch(addblogs(formData));
  };
  

  useEffect(() => {
    if (postError) {
      message.error(postError);
      dispatch(clearState());
    }
    if (postSuccess) {
      message.success("Blog added successfully!");
      dispatch(clearState());
      navigate("/blogs");
    }
  }, [postError, postSuccess]);

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleUploadChangeProfile = (info: UploadChangeParam) => {
    const file = info.fileList[0]?.originFileObj as RcFile; // Get the actual file
  
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === FileReader.DONE) {
          setUploadedFileProfile({
            preview: reader.result as string,
            file: file, // Store the file for upload
          });
        }
      };
  
      reader.onerror = () => {
        message.error("Failed to read file. Please try again.");
      };
  
      reader.readAsDataURL(file);
    }
  };
  
  
  return (
    <div className="container-fluid servicess">
      <div className="row">
        <div className="col-sm-12 addnew">
          <h2>Add New Blog</h2>
        </div>
        <div className="col-sm-12" style={{ paddingTop: "30px" }}>
          <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <div className="row">
              <div className="col-sm-12">
                <Form.Item 
                  label="Title" 
                  name="Title" 
                  rules={[{ required: true, message: "Please input the Title!" }]}
                > 
                  <Input placeholder="Title" />
                </Form.Item>
                          </div>
                          
                          <div className="col-sm-12">
                <Form.Item 
                  label="Image URL" 
                  name="BgImage" 
                  rules={[
                                                            { required: true, message: 'File URL is required!' },
                                                            { type: 'url', message: 'Please enter a valid URL!' },
                                                          ]}
                > 
                  <Input placeholder="Background Image" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item 
                  label="Description" 
                  name="Description" 
                  rules={[{ required: true, message: "Please input the description!" }]}
                > 
                  <QuillEditor data={quillData} onChange={handleQuillChange} />
                </Form.Item>
              </div>

             
                          

              <div className="col-sm-12" style={{ textAlign: "right", marginTop: "20px" }}>
                <Button type="primary" htmlType="submit" className="spinnnnerr" loading={loading}>
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddBlogPage;
