import { createSlice } from "@reduxjs/toolkit";

interface dashboardState {
    loading: boolean;
    error: string | null;
    success: string | null;
    data: any;
}

const initialState: dashboardState = {
    loading: false,
    error: null,
    success: null,
    data: {},
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        dashboardListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        dashboardListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.success = action.payload.data.message;
        },
        dashboardListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
        },
    },
});

export const {
    dashboardListingStart,
    dashboardListingSuccess,
    dashboardListingFailure,
    clearState,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;