import { createSlice } from "@reduxjs/toolkit";

interface instructorState {
    loading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    data: any;
}

const initialState: instructorState = {
    loading: false,
    error: null,
    deleteLoading: false,
    success: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const instructorSlice = createSlice({
    name: "instructor",
    initialState,
    reducers: {
        instructorListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        instructorListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        instructorListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singleinstructorStart(state) {
            state.loading = true;
            state.error = null;
        },
        singleinstructorSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.success = action.payload.data.message;
        },
        singleinstructorFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteinstructorStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteinstructorSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteinstructorFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.deleteLoading = false;
        },
    },
});

export const {
    instructorListingStart,
    instructorListingSuccess,
    instructorListingFailure,
    singleinstructorStart,
    singleinstructorFailure,
    singleinstructorSuccess,
    deleteinstructorStart,
    deleteinstructorFailure,
    deleteinstructorSuccess,
    clearState,
} = instructorSlice.actions;

export default instructorSlice.reducer;