import React from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Courses from "./Pages/Courses";
import CourseDetail from "./Pages/CourseDetail";
import AddCourse from "./Pages/AddCourse";
import Workshops from "./Pages/Workshops";
import WorkshopDetails from "./Pages/WorkshopDetails";
import Users from "./Pages/Users";
import UsersDetails from "./Pages/UsersDetails";
import Book from "./Pages/Book";
import Cookies from "js-cookie";
import ProtectedRoute from "./Components/ProtectedRoute";
import BookDetail from "./Pages/BookDetail";
import Profile from "./Pages/Profile";
import Instructors from "./Pages/Instructors";
import AddBook from "./Pages/AddBook";
import AddNewWorkshop from "./Pages/AddNewWorkshop";
import InstructorDetails from "./Pages/InstructorDetails";
import CourseSection from "./Pages/CourseSection";
import Services from "./Pages/Services";
import AddService from "./Pages/AddService";
import EditService from "./Pages/EditService";
import AddInstructor from "./Pages/AddInstructor";
import AddCourseSection from "./Pages/AddCourseSection";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetCode from "./Pages/ResetCode";
import NewPassword from "./Pages/NewPassword";
import HelpCenter from "./Pages/HelpCenter";
import Blogs from "./Pages/Blogs";
import AddBlogPage from "./Pages/AddBlogs";
import EditBlogPage from "./Pages/EditBlogs";

function App() {
  const token = Cookies.get("token");
  let element = useRoutes([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "dashboard",
      element: (
        <ProtectedRoute token={token}>
          <Dashboard />
        </ProtectedRoute>
      ),
    },
    {
      path: "profile",
      element: (
        <ProtectedRoute token={token}>
          <Profile />
        </ProtectedRoute>
      ),
    },
    {
      path: "users",
      element: (
        <ProtectedRoute token={token}>
          <Users />
        </ProtectedRoute>
      ),
    },
    {
      path: "users/:id",
      element: (
        <ProtectedRoute token={token}>
          <UsersDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "instructors",
      element: (
        <ProtectedRoute token={token}>
          <Instructors />
        </ProtectedRoute>
      ),
    },
    {
      path: "helpcenter",
      element: (
        <ProtectedRoute token={token}>
          <HelpCenter />
        </ProtectedRoute>
      ),
    },

    {
      path: "blogs",
      element: (
        <ProtectedRoute token={token}>
          <Blogs />
        </ProtectedRoute>
      ),
    },
    {
      path: "blogs/add",
      element: (
        <ProtectedRoute token={token}>
          <AddBlogPage />
        </ProtectedRoute>
      ),
    },

    {
      path: "blogs/:id",
      element: (
        <ProtectedRoute token={token}>
          <EditBlogPage />
        </ProtectedRoute>
      ),
    },

    {
      path: "instructors/add",
      element: (
        <ProtectedRoute token={token}>
          <AddInstructor />
        </ProtectedRoute>
      ),
    },
    {
      path: "instructors/:id",
      element: (
        <ProtectedRoute token={token}>
          <InstructorDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "books",
      element: (
        <ProtectedRoute token={token}>
          <Book />
        </ProtectedRoute>
      ),
    },
    {
      path: "workshops",
      element: (
        <ProtectedRoute token={token}>
          <Workshops />
        </ProtectedRoute>
      ),
    },
    {
      path: "workshops/add",
      element: (
        <ProtectedRoute token={token}>
          <AddNewWorkshop />
        </ProtectedRoute>
      ),
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />
    },
    {
      path: "reset-code",
      element: <ResetCode />
    },
    {
      path: "new-password/:Token",
      element: <NewPassword />
    },
    {
      path: "books/add",
      element: (
        <ProtectedRoute token={token}>
          <AddBook />
        </ProtectedRoute>
      ),
    },
    {
      path: "courses/add-section/:id",
      element: (
        <ProtectedRoute token={token}>
          <AddCourseSection />
        </ProtectedRoute>
      ),
    },
    {
      path: "courses",
      element: (
        <ProtectedRoute token={token}>
          <Courses />
        </ProtectedRoute>
      ),
    },    
    {
      path: "courses/:id",
      element: (
        <ProtectedRoute token={token}>
          <CourseDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: "courses/CourseSections/:id",
      element: (
        <ProtectedRoute token={token}>
          <CourseSection />
        </ProtectedRoute>
      ),
    },
    {
      path: "workshops/:id",
      element: (
        <ProtectedRoute token={token}>
          <WorkshopDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "courses/add",
      element: (
        <ProtectedRoute token={token}>
          <AddCourse />
        </ProtectedRoute>
      ),
    },
    {
      path: "books/:id",
      element: (
        <ProtectedRoute token={token}>
          <BookDetail />
        </ProtectedRoute>
      ),
    },
    {
      path: 'services',
      element: (
        <ProtectedRoute token={token}>
          <Services />
        </ProtectedRoute>
      ),
    },
    {
      path: 'services/add',
      element: (
        <ProtectedRoute token={token}>
          <AddService />
        </ProtectedRoute>
      ),
    },
    {
      path: 'services/:id',
      element: (
        <ProtectedRoute token={token}>
          <EditService />
        </ProtectedRoute>
      ),
    }
  ]);

  return element;
}

export default App;
