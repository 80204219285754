import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    userListingStart,
    userListingSuccess,
    userListingFailure,
    singleUserStart,
    singleUserFailure,
    singleUserSuccess,
    deleteUserStart,
    deleteUserFailure,
    deleteUserSuccess,
} from "../Reducer/userReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getSingleUserResponse, PaginationParams, userListing } from "../../interfaces";

export const getUserListing = createAsyncThunk<userListing, PaginationParams>(
    "user",
    async ({page, pageSize}, thunkAPI) => {
        try {
            thunkAPI.dispatch(userListingStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
                params: {
                    size: pageSize,
                    page,
                },
            };

            const response = await axios.get<userListing>(
                `${process.env.REACT_APP_API_URl}UserRoutes/userListing`,
                config
            );

            thunkAPI.dispatch(userListingSuccess(response));

            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch users";

            thunkAPI.dispatch(userListingFailure(errorMessage));
            throw error;
        }
    }
);


export const getSingleUser = createAsyncThunk<getSingleUserResponse, { id: string }, { rejectValue: string }>(
    "user/getSingleUser",
    async ({ id }, thunkAPI) => {
        try {
            thunkAPI.dispatch(singleUserStart());

            const token = Cookies.get("token");
            const config = {
                headers: {
                    Authorization: token,
                },
            };

            const response = await axios.get<getSingleUserResponse>(
                `${process.env.REACT_APP_API_URl}UserRoutes/SingleUser/${id}`,
                config
            );

            thunkAPI.dispatch(singleUserSuccess(response.data));
            return response.data;
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || "Failed to fetch user details";
            thunkAPI.dispatch(singleUserFailure(errorMessage));
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const deleteUser = createAsyncThunk(
    "user/deleteUser",
    async (id: string, thunkAPI) => {
      try {
        thunkAPI.dispatch(deleteUserStart());
  
        const token = Cookies.get("token");
        const config = {
          headers: {
            Authorization: token,
          },
        };
  
        const response = await axios.delete(
          `${process.env.REACT_APP_API_URl}UserRoutes/SoftDelteUser/${id}`, config
        );
  
        thunkAPI.dispatch(deleteUserSuccess(response.data));
        return response.data;
      } catch (error: any) {
        if (error) {
          const errorMessage = error?.response?.data?.message;
          thunkAPI.dispatch(deleteUserFailure(errorMessage));
          return thunkAPI.rejectWithValue(errorMessage);
        }
      }
    }
  );
