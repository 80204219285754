import { Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
// import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { AppDispatch, RootState } from '../../Store';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Pagination/Pagination';
import Loading from '../../Lottie/Loading';
import DeleteModal from '../DeleteModal/DeleteModal';
import { deleteblogs, getblogsListing } from '../../Redux/Action/blogs';
import { clearState } from '../../Redux/Reducer/blogsReducer';

const BlogListing = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data, deleteError, deleteSuccess, loading, deleteLoading } = useSelector((state: RootState) => state.blogs);
    const navigate = useNavigate();
    const [show, setShow] = useState<boolean>(false);
    const [id, setId] = useState<string>();
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(1);

    const handlePageChange = (page: number, pageSize?: number) => {
        setPage(page);
        setPageSize(pageSize || 10);
    };

    const handleDelete = async () => {
        if (id) {
            console.log(id)
            await dispatch(deleteblogs(id));
            setShow(false);
        }
        else {
            message.error("Service does not exist!")
        }
    };

    const handleShow = (id: string) => {
        setShow(true);
        setId(id);
    }
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        dispatch(getblogsListing({ page, pageSize }));
    }, [page, pageSize])

    useEffect(() => {
        if (deleteError) {
            message.error(deleteError);
            dispatch(clearState());
        }
        if (deleteSuccess) {
            message.success(deleteSuccess);
            dispatch(clearState());
            dispatch(getblogsListing({ page, pageSize }));
        }
    }, [dispatch, deleteError, deleteSuccess])

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Blogs</h2>
                    <Button onClick={() => navigate('/blogs/add')}>Add New</Button>
                </div>
            </div>
            {
                loading ?
                    <div><Loading /></div>
                    :
                    <div className="row">

                        <div className="col-sm-12 ttable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.items?.length > 0 ?
                                        
                                        data?.items?.map((services: any, index: number) => (
                                            <tr key={services._id}>
                                                <td onClick={() => navigate(`/blogs/${services._id}`)}>{index + 1}</td>
                                                <td onClick={() => navigate(`/blogs/${services._id}`)}><b>{services?.Title}</b></td>
                                                <td onClick={() => navigate(`/blogs/${services._id}`)}>
  {services?.Description?.slice(0, 100)}{services?.Description?.length > 100 ? "..." : ""}
</td>                                                <td onClick={() => navigate(`/blogs/${services._id}`)}><img src={services?.BgImage} alt='BgImage' className='BgImage'/></td>
                                                <td className='deleete'>
                                                    <div className='actionsss'>
                                                        <DeleteOutlined className='del' onClick={() => handleShow(services._id)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        : 

                                        <tr className='noDataa'>
                                            <td colSpan={5}>No Data</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="col-sm-12 paginatitton">
                            <Pagination
                                TotalData={data?.totalCount || 0}
                                handlePageChange={handlePageChange}
                                currentPage={page}
                            />
                        </div>
                    </div>
            }

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <DeleteModal handleClose={handleClose} handleDelete={handleDelete} loading={deleteLoading} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default BlogListing;