import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

interface bookState {
    loading: boolean;
    deleteLoading: boolean;
    editLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    editError: string | null;
    editSuccess: string | null;
    postError: string | null;
    postSuccess: string | null;
    data: any;
}

const initialState: bookState = {
    loading: false,
    editLoading: false,
    editError: null,
    deleteLoading: false,
    error: null,
    success: null,
    postSuccess: null,
    postError: null,
    editSuccess: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const bookSlice = createSlice({
    name: "book",
    initialState,
    reducers: {
        bookListingStart(state) {
            state.loading = true;
            state.error = null;
        },
        bookListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        bookListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteBookStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteBookSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteBookFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        getSingleBookStart(state) {
            state.loading = true;
        },
        getSingleBookSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        getSingleBookFailure(state, action) {
            state.loading = false;
            message.error(action.payload)
        },
        editBookStart(state) {
            state.editLoading = true;
            state.editError = null;
        },
        editBookSuccess(state, action) {
            state.editLoading = false;
            state.data = action.payload.data;
            state.editSuccess = action.payload.data.message;
        },
        editBookFailure(state, action) {
            state.editLoading = false;
            state.editError = action.payload;
        },
        addBookStart(state) {
            state.loading = true;
            state.postError = null;
        },
        addBookSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.postSuccess = action.payload.data.message;
        },
        addBookFailure(state, action) {
            state.loading = false;
            state.postError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.deleteLoading = false;
            state.editLoading = false;
            state.editError = null;
            state.editSuccess = null;
            state.postError = null;
            state.postSuccess = null;
        },
    },
});

export const {
    bookListingStart,
    bookListingSuccess,
    bookListingFailure,
    deleteBookFailure,
    deleteBookStart,
    deleteBookSuccess,
    getSingleBookStart,
    getSingleBookSuccess,
    getSingleBookFailure,
    editBookStart,
    editBookFailure,
    editBookSuccess,
    addBookFailure,
    addBookStart,
    addBookSuccess,
    clearState,
} = bookSlice.actions;

export default bookSlice.reducer;