import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Upload, message, Select, InputNumber } from 'antd';
import type { UploadFile, UploadProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { clearState } from '../../Redux/Reducer/booksReducer';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { addBook } from '../../Redux/Action/books';
import { getInstructorListing } from '../../Redux/Action/Instructor';

const AddBookPage = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { data } = useSelector((state: RootState) => state.instructor);
    const { postError, postSuccess, loading } = useSelector((state: RootState) => state.book);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);
    const [fileList, setFileList] = useState<UploadFile[]>([]);


    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch])


    useEffect(() => {
        if (postError) {
            message.error(postError);
            dispatch(clearState());
        }
        if (postSuccess) {
            message.success(postSuccess);
            dispatch(clearState());
            navigate('/books')
        }
    }, [postSuccess, postError]);

    const onFinish = (values: any) => {
        // const formData = new FormData();
        // formData.append('Name', values.Name);
        // formData.append('Price', values.Price);
        // formData.append('Duration', values.Duration);
        // formData.append('AudioLink', values.AudioLink);
        // formData.append('PdfLink', values.PdfLink);
        // formData.append('Instructor', values.Instructor);
        // formData.append('Description', values.Description);
    
        // if (fileList[0] && fileList[0].originFileObj) {
        //     formData.append('BgImage', fileList[0].originFileObj); 
        // }
    
        dispatch(addBook(values));
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    // const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    //     setFileList(newFileList);
    // };

    // const onPreview = async (file: UploadFile) => {
    //     let src = file.url as string;
    //     if (!src) {
    //         src = await new Promise((resolve) => {
    //             const reader = new FileReader();
    //             reader.readAsDataURL(file.originFileObj as File);
    //             reader.onload = () => resolve(reader.result as string);
    //         });
    //     }
    //     const image = new Image();
    //     image.src = src;
    //     const imgWindow = window.open(src);
    //     imgWindow?.document.write(image.outerHTML);
    // };

    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Add New Book</h2>
                </div>

                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-sm-6">
                                <Form.Item
                                    label="Name"
                                    name="Name"
                                    rules={[{ required: true, message: 'Please input the Name!' }]}
                                >
                                    <Input placeholder='Name' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Price"
                                    name="Price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input the Price!'
                                        }
                                    ]}
                                >
                                    <InputNumber min={1} placeholder='Price' />
                                </Form.Item>
                            </div>


                            <div className="col-sm-6">
                                <Form.Item
                                    label="Duration"
                                    name="Duration"
                                    rules={[{ required: true, message: 'Please input the Duration in mins or hours!' }]}
                                >
                                    <Input placeholder='Duration in mins or hours' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Audio Link"
                                    name="AudioLink"
                                    rules={[
                                        { 
                                            required: true, 
                                            message: 'Please input the Audio Link!' 
                                        },
                                        {
                                            type: 'url',
                                            message: 'Please enter a valid URL!'
                                        }
                                    ]}
                                >
                                    <Input placeholder='Audio Link' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Pdf Link"
                                    name="PdfLink"
                                    rules={[
                                        { 
                                            required: true, 
                                            message: 'Please input the Pdf Link!' 
                                        },
                                        {
                                            type: 'url',
                                            message: 'Please enter a valid URL!'
                                        }
                                    ]}
                                >
                                    <Input placeholder='Pdf Link' />
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Author"
                                    name="Instructor"
                                    rules={[{ required: true, message: 'Please select the Author!' }]}
                                >
                                    <Select placeholder='Author'>
                                        {data?.items?.map((instructor: any, index: number) => (
                                            <Select.Option value={instructor?._id} key={index}>{instructor?.Name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-sm-6">
                                <Form.Item
                                    label="Bg Image URL"
                                    name="BgImage"
                                    rules={[
                                        { required: true, message: 'Please input the Bg Image Link!' },
                                        { type: 'url', message: 'Please enter a valid URL!' },
                                    ]}
                                >
                                    <Input placeholder="Bg Image URL" />
                                </Form.Item>
                            </div>

                            <div className="col-sm-12">
                                <Form.Item
                                    label="Description"
                                    name="Description"
                                    rules={[{ required: true, message: 'Please input the description!' }]}
                                >
                                    <Input.TextArea placeholder='Book Description' rows={5} />
                                </Form.Item>
                            </div>

                            {/* <div className="col-sm-12">
                                <Upload
                                    name='BgImage'
                                    maxCount={1}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                >
                                    {fileList.length < 2 && '+ Upload'}
                                </Upload>
                            </div> */}

                            <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                    {loading ?
                                        <>
                                            <Spin indicator={<LoadingOutlined spin />} />
                                        </>
                                        :
                                        <></>
                                    }
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddBookPage;