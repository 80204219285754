import React from 'react'
import Sidebar from '../Components/Sidebar/Sidebar'
import CourseSectiomDetailPage from '../Components/Coursess/CourseSectiomDetailPage'

const CourseSection = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-sm-3 sidebar">
                    <Sidebar />
                </div>
                <div className="col-sm-9 noSide">
                    <CourseSectiomDetailPage />
                </div>
            </div>
        </div>
    )
}

export default CourseSection
