import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Button, message, Select, InputNumber, Descriptions } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import {  editblogs, getSingleblogs } from '../../Redux/Action/blogs'
import { clearState } from '../../Redux/Reducer/blogsReducer';
import QuillEditor from './QuillEditor';

// const {option} = Select;

const EditBlogPage = () => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useDispatch<AppDispatch>();
    const { editError, editSuccess, loading, data } = useSelector((state: RootState) => state.blogs);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);
  const [quillData, setQuillData] = useState("");

 

    useEffect(() => {
        if (id) {
            dispatch(getSingleblogs({ id }));
        }
    }, [dispatch, id]);


    useEffect(() => {
        if (editError) {
            message.error(editError);
            dispatch(clearState());
        }
        if (editSuccess) {
            message.success(editSuccess);
            dispatch(clearState());
            navigate('/blogs')
        }
    }, [editSuccess, editError]);

    const onFinish = (values: any) => {
        const formData = new FormData();
        formData.append('BgImage', values.BgImage);
        formData.append('Description', values.Description);
        formData.append('Title', values.Title);
 
        if(id)
        {
            dispatch(editblogs({id, formData}));
        }
    };
    const handleQuillChange = (html: string) => {
        setQuillData(html);
      };
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (data) {
            setQuillData(data?.Description || ""); // Ensure `quillData` is updated first
            form.setFieldsValue({
                Title: data?.Title,
                BgImage: data?.BgImage,
                Description: data?.Description, // Ensure this is set
            });
        }
    }, [data, form]); 
    


    return (
        <div className='container-fluid servicess'>
            <div className="row">
                <div className="col-sm-12 addnew">
                    <h2>Edit Blogs</h2>
                </div>

                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                    <Form
                        form={form}
                        layout='vertical'
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={data}
                    >
                        <div className="row">
                        <div className="col-sm-12">
                <Form.Item 
                  label="Title" 
                  name="Title" 
                  rules={[{ required: true, message: "Please input the Title!" }]}
                > 
                  <Input placeholder="Title" />
                </Form.Item>
                          </div>
                          
                          <div className="col-sm-12">
                <Form.Item 
                  label="Image URL" 
                  name="BgImage" 
                  rules={[
                                                            { required: true, message: 'File URL is required!' },
                                                            { type: 'url', message: 'Please enter a valid URL!' },
                                                          ]}
                > 
                  <Input placeholder="Background Image" />
                </Form.Item>
              </div>

              <div className="col-sm-12">
                <Form.Item 
                  label="Description" 
                  name="Description" 
                  rules={[{ required: true, message: "Please input the description!" }]}
                > 
                  <QuillEditor data={quillData} onChange={handleQuillChange} />
                </Form.Item>
              </div>

                            <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                    {loading ?
                                        <>
                                            <Spin indicator={<LoadingOutlined spin />} />
                                        </>
                                        :
                                        <></>
                                    }
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default EditBlogPage;