import React from "react";
import { Pagination as AntPagination } from "antd";

interface PaginationProps {
  TotalData: number;
  handlePageChange: (page: number, pageSize?: number) => void;
  currentPage: number;
  pageSize?: number; 
}


const Pagination: React.FC<PaginationProps> = ({ TotalData, handlePageChange, currentPage, pageSize }) => {
  return (
    <nav className="paginationMain pagination">
      <AntPagination
        total={TotalData}
        current={currentPage}
        pageSize={pageSize} 
        onChange={handlePageChange}
        showTotal={(total) => `Total ${total} items`}
        showSizeChanger
        onShowSizeChange={handlePageChange}
      />
    </nav>
  );
};

export default Pagination;
