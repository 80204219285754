import { createSlice } from "@reduxjs/toolkit";

interface helpcenterState {
    loading: boolean;
    deleteLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    data: any;
}

const initialState: helpcenterState = {
    loading: false,
    error: null,
    deleteLoading: false,
    success: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const helpcenterSlice = createSlice({
    name: "helpcenter",
    initialState,
    reducers: {
        helpcenterListingStart(state) {
            state.loading = true;
            state.error = null;
        },

        helpcenterListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        helpcenterListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        singlehelpcenterStart(state) {
            state.loading = true;
            state.error = null;
        },
        singlehelpcenterSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.success = action.payload.data.message;
        },
        singlehelpcenterFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deletehelpcenterStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deletehelpcenterSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deletehelpcenterFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.deleteLoading = false;
        },
    },
});

export const {
    helpcenterListingStart,
    helpcenterListingSuccess,
    helpcenterListingFailure,
    singlehelpcenterStart,
    singlehelpcenterFailure,
    singlehelpcenterSuccess,
    deletehelpcenterStart,
    deletehelpcenterFailure,
    deletehelpcenterSuccess,
    clearState,
} = helpcenterSlice.actions;

export default helpcenterSlice.reducer;