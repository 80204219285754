import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, InputNumber, message, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { addWorkshop } from '../../Redux/Action/workshop';
import Loading from '../../Lottie/Loading';
import { getInstructorListing } from '../../Redux/Action/Instructor';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { clearState } from '../../Redux/Reducer/workshopReducer';

const AddNewWorkshop = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { data } = useSelector((state: RootState) => state.instructor);
    const { workshopData, loading, postError, postSuccess } = useSelector((state: RootState) => state.workshop);
    const [form] = Form.useForm();

    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        dispatch(getInstructorListing({ page, pageSize }));
    }, [dispatch])

    const onFinish = (values: any) => {

        dispatch(addWorkshop(values));
    };  

    useEffect(() => {
        if (postError) {
            message.error(postError);
            dispatch(clearState());
        }
        if (postSuccess) {
            message.success(postSuccess);
            dispatch(clearState());
            navigate('/workshops')
        }
    }, [postSuccess, postError]);


    return (
        <div className='container-fluid servicess'>
            {loading ? (
                <div>
                    <Loading />
                </div>
            ) : (
                <div className="row">
                    <div className="col-sm-12 addnew" style={{ marginBottom: '20px' }}>
                        <h2>Add New Workshop</h2>
                    </div>

                    <div className="col-sm-12">
                        <Form
                            initialValues={workshopData}
                            layout='vertical'
                            form={form}
                            onFinish={onFinish}
                        >
                            <div className="row">
                                <div className="col-sm-6">
                                    <Form.Item
                                        name="Name"
                                        label="Name"
                                        rules={[{ required: true, message: 'Please input workshop name!' }]}
                                    >
                                        <Input placeholder='Name' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        name="Instructor"
                                        label="Instructor"
                                        rules={[{ required: true, message: 'Please select the Instructor!' }]}
                                    >
                                        <Select
                                            placeholder="Instructor"
                                        >
                                            {data?.items?.map((instructor: any, index: number) => (
                                                <Select.Option value={instructor?._id} key={index}>
                                                    {instructor?.Name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        name="Duration"
                                        label="Duration"
                                        rules={[{ required: true, message: 'Please input the Duration in mins or hours!' }]}
                                    >
                                        <Input placeholder='Duration' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        name="Price"
                                        label="Price"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input the Price!'
                                            }
                                        ]}
                                    >
                                        <InputNumber min={1} placeholder='Price' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-6">
                                    <Form.Item
                                        name="File"
                                        label="Image Url"
                                        rules={[
                                            { 
                                                required: true, 
                                                message: 'Please input the Image Url!' 
                                            },
                                            {
                                                type: 'url',
                                                message: 'Please enter a valid URL!'
                                            }
                                        ]}
                                    >
                                        <Input placeholder='Image Url' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12">
                                    <Form.Item
                                        name="Description"
                                        label="Description"
                                        rules={[{ required: true, message: 'Please input the description!' }]}
                                    >
                                        <Input.TextArea rows={5} placeholder='Description' />
                                    </Form.Item>
                                </div>

                                <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                    <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                        {loading ?
                                            <>
                                                <Spin indicator={<LoadingOutlined spin />} />
                                            </>
                                            :
                                            <></>
                                        }
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddNewWorkshop;