import React, { useEffect } from 'react';
import img1 from '../../Assets/logo.png';
import { Form, FormProps, Button, Input, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Login } from '../../Redux/Action/authAdmin';
import { clearState } from '../../Redux/Reducer/authAdminReducer';
import type { RootState, AppDispatch } from '../../Store';
import Cookies from 'js-cookie';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Page: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>(); 
    const { error, success, loading } = useSelector((state: RootState) => state.authAdmin);
    const navigate = useNavigate();
    const token = Cookies.get("token");

    useEffect(() => {
        if(token){
            navigate('/users')
        }
    }, [token])
    type FieldType = {
        Email: string;
        Password: string;
    };

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        await dispatch(Login(values));
        console.log(values);
        navigate('/users');
    };

    const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        if (error) {
            message.error(error);
            dispatch(clearState());
        }
        if (success) {
            message.success(success);
            navigate('/users');
            dispatch(clearState());
        }
    }, [error, success, dispatch, navigate]);

    return (
        <div>
            <div className="container-fluid loginComp">
                <div className="row">
                    <div className="col-sm-6">
                        <img src={img1} alt="Logo" />
                        <Form
                            name="login"
                            initialValues={{ remember: false }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            layout="vertical"
                        >
                            <Form.Item<FieldType>
                                label="Username or Email"
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address!',
                                    },
                                ]}
                            >
                                <Input className="inpputt" placeholder="Email" />
                            </Form.Item>

                            <Form.Item<FieldType>
                                label="Password"
                                name="Password"
                                className='noPadd'
                                rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>

                            <div className='loginLinks'>
                                <Link to='/forgot-password'>
                                    Forgot Your Password?
                                </Link>
                            </div>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                {loading ?
                            <>
                                <Spin indicator={<LoadingOutlined spin />} />
                            </>
                            :
                            <></>
                        }
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Page;