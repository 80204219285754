import { createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

interface serviceState {
    loading: boolean;
    deleteLoading: boolean;
    editLoading: boolean;
    error: string | null;
    success: string | null;
    deleteError: string | null;
    deleteSuccess: string | null;
    editError: string | null;
    editSuccess: string | null;
    postError: string | null;
    postSuccess: string | null;
    data: any;
}

const initialState: serviceState = {
    loading: false,
    editLoading: false,
    editError: null,
    deleteLoading: false,
    error: null,
    success: null,
    postSuccess: null,
    postError: null,
    editSuccess: null,
    deleteError: null,
    deleteSuccess: null,
    data: {},
};

const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
        serviceListingStart(state) {
            state.loading = true;
            state.error = null;
        },
        serviceListingSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data.result;
            state.success = action.payload.data.message;
        },
        serviceListingFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        deleteserviceStart(state) {
            state.deleteLoading = true;
            state.deleteError = null;
        },
        deleteserviceSuccess(state, action) {
            state.deleteLoading = false;
            state.data = action.payload;
            state.deleteSuccess = action.payload.message;
        },
        deleteserviceFailure(state, action) {
            state.deleteLoading = false;
            state.deleteError = action.payload;
        },
        getSingleserviceStart(state) {
            state.loading = true;
        },
        getSingleserviceSuccess(state, action) {
            state.loading = false;
            state.data = action.payload;
        },
        getSingleserviceFailure(state, action) {
            state.loading = false;
            message.error(action.payload)
        },
        editserviceStart(state) {
            state.editLoading = true;
            state.editError = null;
        },
        editserviceSuccess(state, action) {
            state.editLoading = false;
            state.data = action.payload.data;
            state.editSuccess = action.payload.data.message;
        },
        editserviceFailure(state, action) {
            state.editLoading = false;
            state.editError = action.payload;
        },
        addserviceStart(state) {
            state.loading = true;
            state.postError = null;
        },
        addserviceSuccess(state, action) {
            state.loading = false;
            state.data = action.payload.data;
            state.postSuccess = action.payload.data.message;
        },
        addserviceFailure(state, action) {
            state.loading = false;
            state.postError = action.payload;
        },
        clearState: (state) => {
            state.error = null;
            state.success = null;
            state.deleteError = null;
            state.deleteSuccess = null;
            state.deleteLoading = false;
            state.editLoading = false;
            state.editError = null;
            state.editSuccess = null;
            state.postError = null;
            state.postSuccess = null;
        },
    },
});

export const {
    serviceListingStart,
    serviceListingSuccess,
    serviceListingFailure,
    deleteserviceFailure,
    deleteserviceStart,
    deleteserviceSuccess,
    getSingleserviceStart,
    getSingleserviceSuccess,
    getSingleserviceFailure,
    editserviceStart,
    editserviceFailure,
    editserviceSuccess,
    addserviceFailure,
    addserviceStart,
    addserviceSuccess,
    clearState,
} = serviceSlice.actions;

export default serviceSlice.reducer;