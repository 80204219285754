import React from 'react';
import NewPasswordForm from '../Components/Login/NewPasswordForm';

const NewPassword = () => {
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-sm-12">
                    <NewPasswordForm />
                </div>
            </div>
        </div>
    );
};

export default NewPassword;