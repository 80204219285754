import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  // courseSectionListingStart,
  // courseSectionListingSuccess,
  // courseSectionListingFailure,
  singlecourseSectionStart,
  singlecourseSectionFailure,
  singlecourseSectionSuccess,
  editcourseSectionStart,
  editcourseSectionFailure,
  editcourseSectionSuccess,
  addcourseSectionStart,
  addcourseSectionFailure,
  addcourseSectionSuccess,
  deletecourseSectionStart,
  deletecourseSectionFailure,
  deletecourseSectionSuccess
} from "../Reducer/courseSectionReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { SingleServiceResponse } from "../../interfaces";

// export const getCourseListing = createAsyncThunk<ServiceListingResponse, PaginationParams>(
//   "courseSection",
//   async ({ page, pageSize }, thunkAPI) => {
//     try {
//       thunkAPI.dispatch(courseListingStart());

//       const token = Cookies.get("token");
//       const config = {
//         headers: {
//           Authorization: token,
//         },
//         params: {
//           size: pageSize,
//           page,
//         },
//       };

//       const response = await axios.get<ServiceListingResponse>(
//         `${process.env.REACT_APP_API_URl}CourseRoutes/CoursesListing`,
//         config
//       );

//       thunkAPI.dispatch(courseListingSuccess(response));

//       return response.data;
//     } catch (error: any) {
//       const errorMessage = error?.response?.data?.message || "Failed to fetch course details";

//       thunkAPI.dispatch(courseListingFailure(errorMessage));
//       throw error;
//     }
//   }
// );


export const getSingleCourseSection = createAsyncThunk<SingleServiceResponse, { id: string }, { rejectValue: string }>(
  "courseSection/getSingleCourseSection",
  async ({ id }, thunkAPI) => {
    try {
      thunkAPI.dispatch(singlecourseSectionStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get<SingleServiceResponse>(
        `${process.env.REACT_APP_API_URl}CourseRoutes/SingleCourse/${id}`,
        config
      );

      thunkAPI.dispatch(singlecourseSectionSuccess(response.data));
      return response.data;
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message || "Failed to fetch course section details";
      thunkAPI.dispatch(singlecourseSectionFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const addcourseSection = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
  "courseSection/addcourseSection",
  async ({id, formData}, thunkAPI) => {
    try {
      thunkAPI.dispatch(addcourseSectionStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URl}CourseSectionRoutes/CreateCourseSection/${id}`,
        formData,
        config
      );

      thunkAPI.dispatch(addcourseSectionSuccess(response));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to add course section!";
      thunkAPI.dispatch(addcourseSectionFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const editcourseSection = createAsyncThunk<SingleServiceResponse, { id: string, formData: FormData }, { rejectValue: string }>(
  "courseSection/editcourseSection",
  async ({id, formData}, thunkAPI) => {
    try {
      thunkAPI.dispatch(editcourseSectionStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URl}CourseSectionRoutes/EditCourseSection/${id}`,
        formData,
        config
      );

      thunkAPI.dispatch(editcourseSectionSuccess(response));
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "Failed to edit course section!";
      thunkAPI.dispatch(editcourseSectionFailure(errorMessage));
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const deletecourseSection = createAsyncThunk(
  "courseSection/deletecourseSection",
  async (id: string, thunkAPI) => {
    try {
      thunkAPI.dispatch(deletecourseSectionStart());

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.delete(
        `${process.env.REACT_APP_API_URl}CourseSectionRoutes/SoftDeleteCourseSetion/${id}`, config
      );

      thunkAPI.dispatch(deletecourseSectionSuccess(response.data));
      return response.data;
    } catch (error: any) {
      if (error) {
        const errorMessage = error?.response?.data?.message;
        thunkAPI.dispatch(deletecourseSectionFailure(errorMessage));
        return thunkAPI.rejectWithValue(errorMessage);
      }
    }
  }
);