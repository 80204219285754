import { Form, Input, Button, message, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Store';
import { LoadingOutlined } from '@ant-design/icons';
import { addcourseSection } from '../../Redux/Action/courseSection';
import { clearState } from '../../Redux/Reducer/courseSectionReducer';
import { useNavigate, useParams } from 'react-router-dom';


const AddNewCourseSection = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { postSuccess, postError, loading } = useSelector((state: RootState) => state.courseSection);
    const [form] = Form.useForm();

    useEffect(() => {
        if (postError) {
            message.error(postError);
            dispatch(clearState());
        }
        if (postSuccess) {
            message.success(postSuccess);
            dispatch(clearState());
            navigate(`courses/${id}`)
            // handleClose();
            navigate(`courses/${id}`)
        }
    }, [postSuccess, postError, dispatch]);

    const onFinish = (values: any) => {
        const formattedSections = values.CourseSectionParts.map((section: any) => ({
            Title: section.Title,
            File: section.File,
            Duration: section.Duration,
          }));

        const formData = {
            ...values,
            CourseSectionParts: formattedSections,
        };
        if(id)
        {
            dispatch(addcourseSection({ id, formData }));
        }
    };

    return (
        <div className='container-fluid servicess'>
            <div className="row" style={{ margin: 0 }}>
                <div className="col-sm-12 addnew">
                    <h2>Add New Course Section</h2>
                </div>
                <div className="col-sm-12" style={{ paddingTop: '30px' }}>
                    <Form form={form} layout='vertical' onFinish={onFinish} initialValues={{ CourseSectionParts: [{ Title: '', File: '', Duration: '' }] }}>
                        <div className="row">
                            <div className="col-sm-12">
                                <Form.Item label="Name" name="Name" rules={[{ required: true, message: 'Please input the Name!' }]}>
                                    <Input placeholder='Name' />
                                </Form.Item>
                            </div>
                            <div className="col-sm-12" style={{textAlign: 'right'}}>
                                <Form.List name="CourseSectionParts">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    style={{ width: 'auto' }}
                                                >
                                                    Add Section Part
                                                </Button>
                                            </Form.Item>
                                            {fields.map((field) => (
                                                <div key={field.key} className="row" style={{alignItems: 'center'}}>
                                                    <div className="col-sm-3">
                                                        <Form.Item
                                                            {...field}
                                                            label="Title"
                                                            name={[field.name, 'Title']}
                                                            rules={[{ required: true, message: 'Title is required!' }]}
                                                        >
                                                            <Input placeholder="Title" />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <Form.Item
                                                            {...field}
                                                            label="File"
                                                            name={[field.name, 'File']}
                                                            rules={[
                                                                { required: true, message: 'File URL is required!' },
                                                                { type: 'url', message: 'Please enter a valid URL!' },
                                                            ]}
                                                        >
                                                            <Input placeholder="File URL" />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <Form.Item
                                                            {...field}
                                                            label="Duration"
                                                            name={[field.name, 'Duration']}
                                                            rules={[{ required: true, message: 'Duration is required!' }]}
                                                        >
                                                            <Input placeholder="Duration (e.g., 210 min)" />
                                                        </Form.Item>
                                                    </div>
                                                    <div className="col-sm-2" style={{ textAlign: 'right' }}>
                                                        <Button type="link" danger onClick={() => remove(field.name)}>
                                                            Remove Section Part
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <div className="col-sm-12" style={{ textAlign: 'right', marginTop: '20px' }}>
                                <Button type="primary" htmlType="submit" disabled={loading} className='spinnnnerr'>
                                    {loading ?
                                        <>
                                            <Spin indicator={<LoadingOutlined spin />} />
                                        </>
                                        :
                                        null
                                    }                                    
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default AddNewCourseSection;
